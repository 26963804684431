import React from 'react'

import * as styles from './ButtonsCubeSection.module.scss'

import { Title, SubTitle, DefaultLink } from 'ui'
import SectionWithScrollBg from 'components/SectionWithScrollBg'
import Buttons from './Buttons'

const ButtonsCubeSection = ({
  title,
  subTitle,
  mapItems,
  withCounters,
  linkTo,
  linkTitle,
  className,
}) => {
  return (
    <SectionWithScrollBg className={className}>
      <div className="row">
        <Title text={title} />
        {subTitle && (
          <div className="col-xxl-8 col-lg-8 col-md-12">
            <SubTitle text={subTitle} />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-xxl-12 col-lg-12 col-md-12">
          <Buttons mapItems={mapItems} withCounters={withCounters} />
        </div>
        {linkTo && linkTitle && (
          <div className={styles.linkWrap}>
            <DefaultLink to={linkTo} title={linkTitle} />
          </div>
        )}
      </div>
    </SectionWithScrollBg>
  )
}
export default ButtonsCubeSection
