import React, { useEffect, useRef, useState } from 'react'

import * as styles from './Content.module.scss'
import cn from 'classnames'

import { SubTitle, DefaultLink } from 'ui'

const Content = ({
  onRenderContent,
  description,
  descriptionClass,
  linkClass,
  url,
  containerWidth,
  open,
}) => {
  const [isHidden, setisHidden] = useState(true)
  const contentRef = useRef(null)

  useEffect(() => {
    if (contentRef.current?.offsetHeight > 0) {
      contentRef.current.style.setProperty(
        '--height',
        `${contentRef.current.offsetHeight}px`,
      )
      setisHidden(false)
    }
  }, [containerWidth])

  useEffect(() => {
    if (contentRef.current?.offsetHeight > 0 && open) {
      onRenderContent(contentRef.current?.offsetHeight)
    }
  }, [open])

  return (
    <div
      className={cn(
        styles.container,
        isHidden ? styles.none : open ? styles.fadeIn : styles.fadeOut,
      )}
      style={{ width: `${containerWidth - 64}px` }}
      ref={contentRef}>
      <SubTitle
        text={description}
        variant="small"
        className={descriptionClass}
      />
      {url && (
        <div className={linkClass}>
          <DefaultLink to={url} variant={['small', 'light']} title="See More" />
        </div>
      )}
    </div>
  )
}

export default Content
