import React, { useRef, useEffect } from 'react'
import * as styles from './FallingSquare.module.scss'
import cn from 'classnames'

import Square from 'ui/Square'

const getIcon = (arr, index) => {
  try {
    if (arr[index].icon.startsWith('../assets')) {
      return require(`content/assets${arr[index].icon.slice(9)}`).default
    }
    return require(`content/assets/${arr[index].icon}`)
  } catch (error) {
    console.log('Icon not found')
    console.log(error)
    return null
  }
}

const FallingSquare = ({
  mapItems,
  activeBtnId,
  inactiveBlocksHeight,
  currentBlockHeight,
}) => {
  const squareRef = useRef(null)
  const containerRef = useRef(null)

  const activeIndex = +activeBtnId.slice(-1)

  useEffect(() => {
    squareRef.current.style?.setProperty(
      '--rotation',
      `${mapItems[activeIndex].rotation}deg`,
    )
    containerRef.current.style?.setProperty(
      '--inactiveBlocksHeight',
      `${inactiveBlocksHeight}px`,
    )
    containerRef.current.style?.setProperty(
      '--currentBlockHeight',
      `${currentBlockHeight}px`,
    )
    containerRef.current.style?.setProperty(
      '--squareHeight',
      `${squareRef.current.clientHeight}px`,
    )
  }, [activeBtnId, inactiveBlocksHeight, currentBlockHeight])

  return (
    <div className={cn('col-4', styles.squareWrap)}>
      <div
        className={cn(styles.container, styles.transition)}
        ref={containerRef}>
        <Square Icon={getIcon(mapItems, activeIndex)} ref={squareRef} />
      </div>
    </div>
  )
}

export default FallingSquare
