import * as styles from './ShowedIndex.module.scss'

import React from 'react'

import { Title } from 'ui'

const ShowedIndex = ({ index }) => {
  return (
    <div className={styles.container}>
      <Title text={index} variant="small" className={styles.text} />
    </div>
  )
}

export default ShowedIndex
