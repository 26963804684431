import React, { useState, useCallback } from 'react'
import * as styles from './Buttons.module.scss'
import cn from 'classnames'

import ButtonWithMenu from './ButtonWithMenu'
import FallingSquare from './FallingSquare'

const Buttons = ({ mapItems, withCounters }) => {
  const [activeBtnId, setActiveBtnId] = useState('ServicesSection-radio-btn-0')
  const [currentBlockHeight, setCurrentBlockHeight] = useState(0)
  const [inactiveBlocksHeight, setInactiveBlocksHeight] = useState(0)

  const radioGroupName = 'ServicesSection-radio-group'

  const onChangeCurrentBlockHeight = useCallback(
    h => setCurrentBlockHeight(h),
    [],
  )
  const incrementInactiveBlocksHeight = useCallback(
    h => setInactiveBlocksHeight(prev => prev + h + 24), // 24 - space beetween blocks
    [],
  )

  const onChange = useCallback(({ target: { checked, id } }) => {
    checked && setActiveBtnId(id)
    setInactiveBlocksHeight(0)
  }, [])

  return (
    <div className={cn('d-flex justify-content-center', styles.container)}>
      <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 d-flex flex-column">
        {mapItems.map(({ title, description, link }, i) => (
          <ButtonWithMenu
            name={radioGroupName}
            id={`ServicesSection-radio-btn-${i}`}
            title={title}
            description={description}
            linkTo={link}
            activeBtnId={activeBtnId}
            onChange={onChange}
            onChangeCurrentBlockHeight={onChangeCurrentBlockHeight}
            incrementInactiveBlocksHeight={incrementInactiveBlocksHeight}
            showedIndex={withCounters ? i + 1 : false}
            key={`ServicesSection-radio-btn-${i}`}
          />
        ))}
      </div>

      {mapItems.length > 0 && (
        <FallingSquare
          mapItems={mapItems}
          activeBtnId={activeBtnId}
          inactiveBlocksHeight={inactiveBlocksHeight}
          currentBlockHeight={currentBlockHeight}
        />
      )}
    </div>
  )
}
export default Buttons
