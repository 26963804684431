import React, { useEffect, useRef, useState, useCallback } from 'react'
import * as styles from './ButtonWithMenu.module.scss'

import { Title } from 'ui'
import Content from './Content'

import { useDelayUnmount, useWindowSize } from 'utils'
import ShowedIndex from './ShowedIndex'
const ButtonWithMenu = ({
  name,
  id,
  activeBtnId,
  title,
  description,
  onChange,
  onChangeCurrentBlockHeight,
  incrementInactiveBlocksHeight,
  showedIndex,
  linkTo,
}) => {
  const [windowWidth] = useWindowSize()

  const [inactiveBlockHeight, setInactiveBlockHeight] = useState(0)
  const [contentHeight, setContentHeight] = useState(0)

  const [blockWidth, setBlockWidth] = useState(false)
  const labelRef = useRef(null)

  const isActive = activeBtnId === id

  const shouldRenderChild = useDelayUnmount(isActive, 350)

  const onRenderContent = useCallback(height => {
    setContentHeight(height)
  }, [])

  useEffect(() => {
    if (
      labelRef.current?.clientHeight &&
      !inactiveBlockHeight &&
      windowWidth > 992
    ) {
      setInactiveBlockHeight(labelRef.current?.clientHeight)
    }
    if (labelRef.current?.clientWidth) {
      setBlockWidth(labelRef.current?.clientWidth)
    }
  }, [windowWidth, inactiveBlockHeight])

  useEffect(() => {
    if (!inactiveBlockHeight) return

    if (contentHeight && activeBtnId === id) {
      onChangeCurrentBlockHeight(contentHeight + inactiveBlockHeight)
    } else {
      if (+id.slice(-1) < +activeBtnId.slice(-1)) {
        incrementInactiveBlocksHeight(inactiveBlockHeight)
      }
    }
  }, [contentHeight, inactiveBlockHeight, activeBtnId])

  return (
    <>
      <input
        type="radio"
        checked={isActive}
        id={id}
        name={name}
        onChange={onChange}
        className={styles.radio}
      />
      <label htmlFor={id} className={styles.label} ref={labelRef}>
        <Title text={title} variant="small" className={styles.title} />
        {showedIndex && <ShowedIndex index={`0${showedIndex}`} />}
        {shouldRenderChild && blockWidth && (
          <Content
            onRenderContent={onRenderContent}
            containerWidth={blockWidth}
            description={description}
            url={linkTo}
            open={isActive}
            descriptionClass={styles.description}
            linkClass={styles.link}
            showedIndex={showedIndex}
          />
        )}
      </label>
    </>
  )
}
export default ButtonWithMenu
